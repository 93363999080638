/* eslint-disable prefer-destructuring */ /* eslint-disable no-trailing-spaces
*/ /* eslint-disable no-restricted-syntax */
<template>
  <div
    id="div-with-loading"
    class="w-100"
    :style="accountancy_event ? 'background-color: #41a7ae;' : ''"
  >
    <AppNavbarFull
      v-if="showLayout"
      ref="refAppNavbarFull"
      :company-image="appNavbarFullImageUrl"
    />

    <div
      v-if="['company-listings-page', 'view-jobs-company'].includes($route.name)"
      class="mx-1 mt-2"
    >
      <b-button
        variant="primary"
        class="m-0"
        @click="goBack()"
      >
        Go Back
      </b-button>
    </div>

    <b-row v-if="specific_company">
      <b-card
        v-if="specific_company_image"
        class="w-100 mx-auto"
      >
        <div class="">
          <h3>All events by :</h3>
        </div>

        <div class="">
          <img
            :src="specific_company_image"
            alt="content-img"
            class="image-size mx-auto self-center"
          >
        </div>
      </b-card>
    </b-row>

    <!-- <b-alert
      class="w-75 mx-auto mt-1"
      variant="danger"
      :show="event_details && event_details.publication_status === 'draft'"
    >
      <div class="alert-body">
        <p>This event is not yet published(draft) thus applicants won't be able to see this event. To publish this event visit the `Event Manager` and click on publish icon button in the actions button.</p>
      </div>
    </b-alert> -->
    <div
      v-if="group_event_image"
      class="d-flex justify-content-center mt-4 mb-4 mx-2 position-relative"
    >

      <img
        v-if="group_event_image !== 'default'"
        :src="group_event_image"
        alt="event-img"
        class="image-size mx-auto"
        :class="event_details.active ? '': 'blurred-image'"
        @click="checkRedirection"
      >

      <div
        v-if="!event_details.active"
        class="overlay-text"
      >
        This event has ended
      </div>
    </div>

    <div v-else>
      <div
        v-if="event_details && event_details.active === false"
        class="overlay-text"
      >
        This event has ended
      </div>

      <div
        v-if="!event_details && !isLoading"
        class="overlay-text"
      >
        Invalid event link
      </div>

    </div>

    <!-- <div
      v-if="group_event_image"
      class="d-flex justify-content-center mt-4 mb-4 mx-2"
    >
      <img
        v-if="group_event_image !== 'default'"
        :src="
          group_event_image
        "
        alt="content-img"
        class="image-size mx-auto"
        @click="checkRedirection"
      >
    </div> -->
    <div
      v-else-if="
        false
          && $isNotEmpty(event_details)
          && $isNotEmpty(event_details.event_name)
          && ['company-listings-page', 'view-jobs-company'].includes($route.name)
      "
      class="d-flex justify-content-center mt-4 mb-4 mx-2"
    >
      <div
        class="p-1 image-size bg-primary bg-lighten-6 w-100 d-flex flex-column align-items-center justify-content-center"
        style="min-height: 10rem;"
      >
        <h1 class="text-primary font-weight-bolder">
          {{ event_details.event_name }}
        </h1>
        <h5 class="text-dark font-weight-bold">
          {{ event_details.organiser_name }}
        </h5>
        <div class="text-center">
          {{
            cleanerIntervals(
              event_details.cleaned_start_date,
              event_details.cleaned_end_date
            )
          }}
          ({{ event_details.cleaned_start_time }} to
          {{ event_details.cleaned_end_time }})
        </div>
      </div>
    </div>

    <div v-else>
      <div
        v-if="event_details && event_details.active === false"
        class="overlay-text"
      >
        This event has ended
      </div>

      <div
        v-if="!event_details && !isLoading"
        class="overlay-text"
      >
        Invalid event link
      </div>

    </div>
    <div
      v-if="event_details && (Object.keys(event_details).length === 0 || event_details.active)"
      class="my-2"
    >
      <div class="p-8">
        <div class="container-fluid px-4">
          <b-row class="w-100 mx-auto">
            <h4
              class="mx-auto"
              :class="accountancy_event ? 'text-white' : 'text-dark'"
            >
              Search by Job Title
            </h4>
          </b-row>
          <b-row class="">
            <b-input-group class="">

              <b-form-input
                v-if="$route.params.company === 'e88e4812-82c8-4d49-8aa1-0b3e3129bb43_g' || $route.params.company === '61de74bc21c14eb016e518cb_g'"
                v-model="selected_event_title"
                class="w-auto"
                :class="{'w-75': isMobile}"
                placeholder="Start typing to search for a specific job title"
                debounce="1000"
                @keyup="searchText"
              />

              <!-- <b-input-group-append
                :class="{'w-25': isMobile}"
              >
                <b-button
                  variant="primary"
                  class="w-100"
                  @click="specificGetEvents()"
                >
                  <feather-icon
                    class="mr-md-1"
                    icon="SearchIcon"
                    size="15"
                  />
                  <span v-if="!isMobile">Search</span>
                </b-button>
              </b-input-group-append> -->

              <v-select
                v-else
                id="vSelectJobPosition"
                v-model="selected_event_title"
                style="width: 100%!important;"
                class="w-auto"
                :class="{'w-75': isMobile}"
                placeholder="Start typing to search for a specific job title"
                :options="all_positions"
                @close="searchText"
                @search="jobPositionSearch"
              />
            </b-input-group>
          </b-row>

          <b-modal
            id="modal-lg"
            ref="share-job-modal"
            centered
            hide-footer
            title="Share Job"
          >
            <div class="d-flex flex-column">
              <h6 class="mx-auto">
                The link that will be shared.
              </h6>
              <b-form-input
                v-model="share_link"
                class="mt-1 mx-auto"
              />
            </div>
            <div class="d-flex flex-wrap justify-content-center p-2">
              <ShareNetwork
                v-for="(info, index) of all_share_icons"
                :key="index"
                class="mx-1"
                :network="info.network"
                :url="getLink(current_item.id)"
                :title="
                  'Check out this ' +
                    current_item.position +
                    ' event organised by ' +
                    current_item.company_name +
                    ' via HeadHunters HQ.'
                "
              >
                <font-awesome-icon
                  :icon="info.name"
                  size="3x"
                  :class="info.styling"
                />
              </ShareNetwork>
            </div>
          </b-modal>
        </div>

        <b-modal
          id="modal-lg"
          ref="filter-job-modal"
          centered
          hide-footer
          title="Job Type"
        >
          <div class="mt-1 mb-1">
            <v-select
              v-model="selected_job_type"
              placeholder="Choose your preferred job type"
              :options="job_types"
              label="text"
              multiple
              class="text-white"
              :reduce="(value) => value.value"
            />
          </div>
        </b-modal>

        <b-modal
          id="modal-lg"
          ref="filter-position-modal"
          centered
          hide-footer
          title="Employment Industry"
        >
          <div
            class="mt-1 mb-1"
          >
            <v-select
              v-model="selected_industry"
              placeholder="Choose your preferred employer industry"
              multiple
              label="value"
              :loading="industrySearchLoading"
              :options="industries"
              class="text-white"
              :reduce="(value) => value.value"
              @search="industrySearch"
              @open="industrySearch"
            />
          </div>
        </b-modal>

        <b-modal
          id="modal-lg"
          ref="filter-employer-modal"
          centered
          hide-footer
          title="Employer Name"
        >
          <div>
            <v-select
              v-model="select_job_source"
              placeholder="Choose the job source"
              multiple
              label="value"
              class="text-white"
              :options="jobSources"
              :reduce="(value) => value.value"
            />
          </div>

        </b-modal>

        <b-modal
          id="modal-lg"
          ref="filter-employer-modal"
          centered
          hide-footer
          title="Employer Name"
        >
          <div>
            <v-select
              v-model="select_company"
              placeholder="Start typing to search the company name"
              multiple
              label="value"
              class="text-white"
              :loading="companySearchLoading"
              :options="companies"
              :reduce="(value) => value.value"
              @search="companySearch"
              @open="companySearch"
            />

          </div>

        </b-modal>

        <b-modal
          id="modal-lg"
          ref="filter-work-modal"
          centered
          hide-footer
          title="Work Location"
        >
          <div>
            <v-select
              v-model="selected_location"
              placeholder="Choose your preferred work location"
              multiple
              label="value"
              :options="all_locations"
              class="text-white"
              :reduce="(value) => value.value"
            />
          </div>

        </b-modal>


         <b-modal
          id="modal-lg"
          ref="filter-work-modal"
          centered
          hide-footer
          title="Flexi Options"
          v-if="$route.params.company === '36963230-8382-4666-b555-b34aabfbca3d_g'"
        >
          <div>
            <v-select
              v-model="selected_flexi"
              placeholder="Choose your preferred flexi options"
              multiple
              label="value"
              :options="all_flexi_filters"
              class="text-white"
              :reduce="(value) => value.value"
            />
          </div>

        </b-modal>

        <b-modal

          id="modal-lg"
          ref="filter-salary-modal"
          centered
          hide-footer
          title="Salary Range"
        >
          <div>
            <h6 class="">
              Per Annum
            </h6>
            <vue-slider
              v-model="salary_range_yearly"
              :enable-cross="false"
              class="w-100 mt-3"
              :contained="true"
              :silent="true"
              :tooltip="'always'"
              :min="min_salary_range"
              :max="max_salary_range"
              :interval="1"
              :tooltip-formatter="salary_formatter"
            />
            <h6 class="">
              Per Monthly
            </h6>
            <vue-slider
              v-model="salary_range_monthly"
              :enable-cross="false"
              class="w-100 mt-3"
              :contained="true"
              :silent="true"
              :tooltip="'always'"
              :min="min_salary_range"
              :max="max_salary_range"
              :interval="1"
              :tooltip-formatter="salary_formatter"
            />
          </div>
        </b-modal>

        <b-modal
          id="modal-lg"
          ref="filter-employment-modal"
          centered
          hide-footer
          title="Employment Type"
        >
          <div>
            <v-select
              v-model="selected_employment_type"
              placeholder="Choose your preferred employment type"
              multiple
              label="text"
              class="text-white"
              :options="employment_types"
              :reduce="(value) => value.value"
            />
          </div>
        </b-modal>

        <b-modal
          id="modal-lg"
          ref="filter-sort-modal"
          centered
          hide-footer
          title="Sort by"
        >

          <div>
            <h6 class="">
              By Position
            </h6>
            <v-select
              v-model="sort_choice"
              label="value"
              class="text-white"
              :options="sort_options"
              :reduce="(value) => value.value"
            />
          </div>
        </b-modal>

        <div
          :class="{ 'invisible': !isMobile }"
          class="filter-button-container"
        >
          <div class="filter-button-box">
            <b-button
              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterEvent()"
            >
              <span class="align-middle"> Job Type</span>
            </b-button>

            <b-button
              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterEmployer()"
            >
              <span class="align-middle">Employer Name</span>
            </b-button>

            <b-button
              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterWork()"
            >
              <span class="align-middle"> Work Location</span>
            </b-button>

            <b-button

              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterSalary()"
            >
              <span class="align-middle"> Salary Range</span>
            </b-button>

            <b-button
              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterEmployment()"
            >
              <span class="align-middle"> Employment Type</span>
            </b-button>

            <b-button
              v-if="false"
              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterSort()"
            >
              <span class="align-middle"> Sort By</span>
            </b-button>
          </div>
        </div>

        <div class="d-flex flex-column flex-sm-column flex-lg-row w-100">
          <!-- <div v-for="(group, i) in eventsGroups" :key="i"> -->
          <!-- .slice(i * 4, (i + 1) * 4) -->

          <b-col
            class=""
            cols="12"
            lg="4"
            sm="12"
          >
            <b-overlay
              :show="searchLoading"
              rounded="sm"
            >
              <div class="p-8">
                <b-card :class="{'d-none' : isMobile}">
                  <div class="w-100">
                    <div
                      class="d-flex flex-row flex-wrap mx-1"
                      :class="
                        expand_search
                          ? 'justify-content-between'
                          : 'justify-content-end'
                      "
                    >
                      <b-button
                        v-if="expand_search"
                        class="align-left"
                        :class="{'w-100': isMobile}"
                        style="width: 150px"
                        block
                        variant="outline-primary"
                        @click="resetFilters"
                      >
                        <feather-icon
                          icon="XCircleIcon"
                          style="margin-right: 4px; margin-bottom: 1px"
                        />
                        <span class="align-middle">Reset Filters</span></b-button>

                      <b-button
                        class="btn-icon d-block d-lg-none"
                        :class="{'w-100': isMobile}"
                        variant="primary"
                        @click="expand_search = !expand_search"
                      >
                        <feather-icon
                          :icon="
                            expand_search ? 'Minimize2Icon' : 'Maximize2Icon'
                          "
                          class=""
                        />
                        <span class="align-middle">{{
                          expand_search ? "" : "Maximize Filters Panel"
                        }}</span>
                      </b-button>
                    </div>
                    <b-form-group class="container-fluid">
                      <div
                        v-if="false && expand_search"
                        class="mt-2 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Sort Positions By
                        </span>

                        <v-select
                          v-model="sort_choice"
                          label="value"
                          class=""
                          :options="sort_options"
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        class="mt-1 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Job Source
                        </span>
                        <v-select
                          v-model="select_job_source"
                          placeholder="Choose the job source"
                          multiple
                          label="value"
                          class="text-white"
                          :options="jobSources"
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        class="mt-1 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Employer Name
                        </span>
                        <v-select
                          v-model="select_company"
                          placeholder="Start typing to search the company name"
                          multiple
                          label="value"
                          class="text-white"
                          :loading="companySearchLoading"
                          :options="companies"
                          :reduce="(value) => value.value"
                          @search="companySearch"
                          @open="companySearch"
                        />
                      </div>

                      <div
                        v-if="
                          !specific_company &&
                            organiser_companies.length > 0 &&
                            expand_search
                        "
                        class="mt-1 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Event Organiser Name
                        </span>
                        <v-select
                          v-model="select_organiser"
                          placeholder="Choose an event organiser"
                          :options="organiser_companies"
                          multiple
                          label="value"
                          class="text-white"
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        v-if="all_locations.length > 0 && expand_search"
                        class="mt-1 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Work Location
                        </span>
                        <v-select
                          v-model="selected_location"
                          placeholder="Choose your preferred work location"
                          multiple
                          label="value"
                          :options="all_locations"
                          class="text-white"
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        v-if="$route.params.company === '36963230-8382-4666-b555-b34aabfbca3d_g' && expand_search"
                        class="mt-1 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Flexi Options
                        </span>
                        <v-select
                          v-model="selected_flexi"
                          placeholder="Choose your preferred flexi option"
                          multiple
                          label="value"
                          :options="all_flexi_filters"
                          class="text-white"
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        v-if="expand_search"
                        class="mt-1 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Salary Range (Per Month)
                        </span>
                        <vue-slider
                          v-model="salary_range"
                          :enable-cross="false"
                          class="w-100 mt-3 cursor-pointer"
                          :contained="true"
                          :silent="true"
                          :tooltip="'always'"
                          :min="min_salary_range"
                          :max="max_salary_range"
                          :interval="1"
                          :tooltip-formatter="salary_formatter"
                        />
                      </div>

                      <div
                        v-if="job_types.length > 0 && expand_search"
                        class="mt-1 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Job Type
                        </span>
                        <v-select
                          v-model="selected_job_type"
                          placeholder="Choose your preferred job type"
                          :options="job_types"
                          label="text"
                          multiple
                          class="text-white"
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        v-if="employment_types.length > 0 && expand_search"
                        class="mt-1 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Employment Type
                        </span>
                        <v-select
                          v-model="selected_employment_type"
                          placeholder="Choose your preferred employment type"
                          multiple
                          label="text"
                          class="text-white"
                          :options="employment_types"
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        v-if="expand_search"
                        class="mt-1 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Employer Industry
                        </span>
                        <v-select
                          v-model="selected_industry"
                          placeholder="Choose your preferred employer industry"
                          multiple
                          label="value"
                          :loading="industrySearchLoading"
                          :options="industries"
                          class="text-white"
                          :reduce="(value) => value.value"
                          @search="industrySearch"
                          @open="industrySearch"
                        />
                      </div>
                      <!-- {{ event_id }} -->
                      <div
                        v-if="all_job_functions.length > 0 && expand_search"
                        class="mt-1 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Job Function
                        </span>
                        <v-select
                          v-model="selected_job_function"
                          placeholder="Choose your preferred job function"
                          multiple
                          label="value"
                          :options="all_job_functions"
                          class="text-white"
                          :reduce="(value) => value.value"
                        />
                      </div>
                      <div
                        v-if="
                          !specific_company &&
                            all_dates.length > 0 &&
                            expand_search
                        "
                        class="mt-1 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Date Of Event
                        </span>
                        <v-select
                          v-model="select_date"
                          label="value"
                          placeholder="Choose the start date of the event"
                          :options="all_dates"
                          multiple
                          class="text-white"
                          :reduce="(value) => value.value"
                        />
                      </div>
                    </b-form-group>
                  </div>
                </b-card>
              </div>
            </b-overlay>
          </b-col>

          <b-overlay
            :show="jobsLoading"
            rounded="sm"
            class="w-100"
            cols="12"
            lg="8"
            sm="12"
          >
            <b-col
              v-if="events_data && events_data.length > 0"
              id="jobs_loading"
              class="w-100"
              sm="12"
            >
              <div
                v-for="(item, index) in events_data"
                :key="index"
                class="w-100"
              >
                <b-card class="job-card d-flex flex-column w-100">
                  <b-row class="m-0 p-0 p-md-1 w-100">
                    <b-col
                      lg="9"
                      sm="12"
                      class="m-0 p-0 pr-1"
                    >
                      <div class="w-100">
                        <b-badge
                          v-if="item.featured && !isFiltered"
                          class="mb-1 bg-main"
                          style="width: 13ch;"
                        >
                          <feather-icon icon="StarIcon" />&nbsp;
                          Featured
                        </b-badge>

                        <div
                          v-if="item.published_fields.includes('employer')"
                          class="d-flex justify-content-between flex-wrap"
                        >
                          <div>
                            <h6
                            v-if="item.event_category === 'join'"
                            class="m-0 p-0"
                          >
                            <span>{{ item.company_name }}</span>
                          </h6>
                          <h5
                            v-else
                            class="m-0 p-0"
                          >
                            {{ item.company_name }}
                          </h5>
                          </div>
                          <div v-if="item.flexi_options && item.flexi_options.length === 3" style="width: 16%; height: 10px;">
                            <img
                              :src=" require('@/assets/images/pages/all-flexi.png')"
                              alt="content-img"
                              class="responsive card-img-top"
                              
                            >
                          </div>

                        </div>

                        <div
                          v-else
                          class="d-flex flex-wrap"
                        >
                          <h6
                            v-if="item.event_category === 'join'"
                            class="m-0 p-0 font-italic"
                          >Confidential
                          </h6>
                          <h5
                            v-else
                            class="m-0 p-0 font-italic"
                          >
                            Confidential
                          </h5>

                        </div>

                        <div class="mt-1 d-flex flex-gap-sm flex-row align-items-center">

                          <h2
                            class="text-primary m-0 p-0"
                            style="font-weight: 400"
                          >
                            {{ item.position }}
                          </h2>

                        </div>
                        <div
                          v-if="item.event_name"
                          class="mt-1"
                        >
                          <p
                            v-if="item.organiser_name && !grouped()"
                            class="m-0 p-0"
                            style="font-weight: 600; font-size: 14px"
                          >
                            <a
                              :href="
                                $router.resolve({
                                  name: ['view-jobs'].includes($route.name) ? 'view-jobs-company' : 'company-listings-page',
                                  params: {company: item.event_id + '_g'}
                                }).href
                              "
                            >{{ item.event_name }}</a>
                            event
                            {{
                              cleanerIntervals(
                                item.cleaned_start_date,
                                item.cleaned_end_date
                              )
                            }}
                            ({{ item.cleaned_start_time }} to
                            {{ item.cleaned_end_time }})
                          </p>
                        </div>

                        <div
                          class="d-flex flex-wrap flex-gap-md mt-1"
                          style="font-weight: 600; font-size: 14px"
                        >
                          <div class="d-flex flex-gap-xs justify-content-between">
                            <font-awesome-icon
                              icon="columns"
                              class="lg"
                            />
                            <h6 class="m-0 p-0 icon-header">
                              {{ getEventType(item.interview_type) }}
                            </h6>
                          </div>

                          <div class="d-flex flex-gap-xs justify-content-between">
                            <font-awesome-icon icon="map-marker-alt" />
                            <h6 class="m-0 p-0 icon-header">
                              {{
                                cleanLocation(
                                  item.work_location,
                                  item.location_zone
                                )
                              }}
                            </h6>
                          </div>

                          <div
                            v-if="item.source_name !== 'efc' || item.published_fields.includes('salary')"
                            class="d-flex flex-gap-xs justify-content-between"
                          >
                            <font-awesome-icon icon="dollar-sign" />

                            <h6
                              v-if="item.min_salary == item.max_salary"
                              class="m-0 p-0 icon-header"
                            >
                              <!-- {{
                                item.max_salary === 1 || item.max_salary === "1" ? "Undisclosed" : item.max_salary
                              }}
                              {{
                                item.max_salary === 1 || item.max_salary === "1"
                                  ? ""
                                  : cleanCycles(item.salary_cycle)
                              }} -->

                              {{
                                $isNotEmpty(item.min_salary) && $isNotEmpty(item.max_salary)
                                  ? `${item.min_salary} - ${item.max_salary} ${cleanCycles(item.salary_cycle)}`
                                  : 'Undisclosed'
                              }}
                            </h6>

                            <h6
                              v-else-if="
                                item.max_salary === 'To be discussed later'
                              "
                              class="m-0 p-0 icon-header"
                            >
                              {{ item.max_salary }}
                            </h6>

                            <h6
                              v-else
                              class="m-0 p-0 icon-header"
                            >
                              {{
                                $isNotEmpty(item.min_salary)
                                  ? `${item.min_salary} - ${item.max_salary} ${cleanCycles(item.salary_cycle)}`
                                  : 'Undisclosed'
                              }}
                            </h6>
                          </div>

                          <div
                            v-else-if="!item.published_fields.includes('salary')"
                            class="d-flex flex-gap-xs justify-content-between"
                          >
                            <font-awesome-icon
                              icon="dollar-sign"
                              class="font-italic"
                            />

                            <h6
                              class="m-0 p-0 icon-header font-italic"
                            >
                              Confidential
                            </h6>
                          </div>

                        </div>
                        
                        <div
                          v-if="item.industry && item.industry.length > 0"
                          class="d-flex mt-1"
                        >
                          <div class="d-flex flex-wrap flex-gap-xs">
                            <div
                              v-for="(tag, index) in item.industry"
                              :key="index"
                            >
                              <b-badge
                                pill
                                variant="dark"
                              >
                                {{ tag }}
                              </b-badge>
                            </div>
                          </div>
                        </div>

                        <h6
                          v-if="!item.organiser_name"
                          class="m-0 p-0 mt-1 text-danger"
                          style="font-size: 14px"
                        >
                          <span>When you click `Apply Now` button you will be
                            redirected to NTUC's Job Security Council - Jobs
                            Alert telegram bot where you will complete the
                            application.</span>
                        </h6>

                        <div
                          v-if="['mcf', 'efc', 'betalented'].includes(item.source_name)"
                          class="mt-1 d-flex flex-gap-sm align-items-center"
                        >
                          <h6
                            class="m-0 p-0 text-warning font-weight-bold"
                            style="font-weight: 600 !important"
                          >
                            Source: {{ item.source_name === 'mcf' ? 'MyCareersFuture': (item.source_name === 'betalented' ? 'Talented' : 'eFinancialCareers' ) }}
                          </h6>
                          <font-awesome-icon
                            v-b-tooltip.hover.top="mcf_message"
                            icon="question-circle"
                            class="text-warning"
                          />
                        </div>

                        <p
                          class="m-0 p-0 fw-bold text-right"
                          style="font-weight: 400; font-size: 14px"
                        >
                          {{ !item.external ? registered[item.id].length : item.applications }} Applications
                        </p>
                      </div>

                    </b-col>

                    <b-col
                      lg="3"
                      sm="12"
                      class="m-0 p-0 mt-1 mt-md-0 w-100"
                    >
                      <div class="d-flex flex-row w-100">
                        <div class="divider bg-warning d-block d-md-none" />

                        <div class="d-flex flex-column flex-gap-y-lg w-100">
                          <div
                            v-if="item.event_category !== 'group'"
                            class="d-flex flex-column w-100"
                          >
                            <b-button
                              :id="`buttonViewMore_${item.id}`"
                              class="mx-auto text-center"
                              :to="'/listing/' + item.id + `${!$route.name.includes('listings-page') ? '/dashboard' : ''}`"
                              variant="primary"
                              block
                            >
                              <feather-icon
                                icon="BookOpenIcon"
                                class="mr-1"
                              />
                              <span
                                class="align-middle"
                              >View More</span></b-button>
                          </div>

                          <div class="d-flex flex-column flex-gap-y-lg">
                            <div
                              v-if="item.external"
                              class="d-flex flex-column w-100"
                            >
                              <b-button
                                :id="`buttonApplyNow-redirect-job_${item.id}`"
                                ref="join"
                                class="mx-auto w-100"
                                variant="primary"
                                block
                                @click="fireRedirect(item, $event)"
                              >
                                <feather-icon
                                  icon="CheckIcon"
                                  class=""
                                />
                                <span class="align-middle">Apply Now</span>
                              </b-button>
                            </div>
                            <div v-else>
                              <b-button
                                v-if="
                                  showEventButtons
                                    && checkIDRegistered( registered, item.id) && !checkProfileCompletion()
                                "
                                ref="join"
                                class="mx-auto w-100"
                                variant="success"
                                block
                                @click="joinEvent(item, $event)"
                              >
                                <feather-icon
                                  icon="CheckIcon"
                                  class="mr-1"
                                />
                                <span
                                  class="align-middle"
                                >Applied</span>
                              </b-button>

                              <div
                                v-else-if="
                                  checkIDRegistered(
                                    registered,
                                    item.id
                                  ) && checkProfileCompletion()
                                "
                                class="d-flex flex-column"
                              >
                                <b-button
                                  variant="warning"
                                  class="mx-auto w-100"
                                  block
                                  @click="partialReg"
                                >
                                  <feather-icon
                                    icon="CheckIcon"
                                    class="mr-1"
                                  />
                                  <span class="align-middle">
                                    Incomplete Application
                                  </span>
                                </b-button>

                                <b-alert
                                  v-if="
                                    checkIDRegistered(
                                      registered,
                                      item.id
                                    ) && checkProfileCompletion()
                                  "
                                  class="w-100 h-auto m-0 p-0"
                                  variant="warning"
                                  show
                                >
                                  <div class="alert-body">
                                    <feather-icon
                                      class="mr-2"
                                      icon="infoIcon"
                                    />
                                    <span>
                                      Complete it via
                                      {{
                                        $router.currentRoute.path == "/events-list"
                                          ? "the Profile page"
                                          : `Manage My Application`
                                      }}
                                    </span>
                                  </div>
                                </b-alert>
                              </div>

                              <b-button
                                v-else
                                :id="`buttonApplyNow-form-job_${item.id}`"
                                ref="join"
                                class="mx-auto"
                                variant="primary"
                                block
                                @click="joinEvent(item, $event)"
                              >
                                <feather-icon
                                  icon="CheckIcon"
                                  class="mr-1"
                                />
                                <span
                                  class="align-middle"
                                >Apply Now</span>
                              </b-button>
                            </div>

                            <b-button
                              class="mx-auto w-100"
                              variant="primary"
                              block
                              @click="shareEvent(item)"
                            >
                              <feather-icon
                                icon="ShareIcon"
                                class="mr-1"
                              />
                              <span
                                class="align-middle"
                              >Share Job</span></b-button>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <hr v-if="item.note">
                  <b-row v-if="item.note">
                    <p
                      style="font-weight: 600; font-size: 14px;white-space: pre-line;"
                      class="mx-2 mt-1"
                    >
                      <span>
                        Note:
                      </span>
                      <br>
                      <span>
                        {{ item.note }}
                      </span>
                    </p>
                  </b-row>
                </b-card>
              </div>
              <div
                v-if="events_data.length > 0"
                class="d-flex w-100 mb-16"
              >
                <SpinnerBtn
                  v-if="viewMoreLoading"
                  class="mx-auto"
                  :variant="'primary'"
                  style="width: 18ch;"
                  :block="false"
                />
                <b-button
                  v-else-if="!loadedAllJobs"
                  class="mx-auto"
                  variant="primary"
                  style="width: 28ch;"
                  @click="loadMoreJobs"
                >View More Jobs</b-button>
                <b-button
                  v-else
                  class="mx-auto"
                  variant="primary"
                  disabled
                  style="width: 28ch;"
                >That's All For Now</b-button>

                <!-- <vs-pagination
                class="vx-col sm:ml-12 lg:ml-0"
                :total="total_events.length"
                v-model="current_job_page"
                :max="10"
              ></vs-pagination> -->
              </div>
            </b-col>
            <b-col
              v-else
              class="w-100"
            >
              <h4
                class="grey mx-auto text-center mt-5"
                :class="accountancy_event ? 'text-white' : 'text-dark'"
              >
                {{ event_exist ? 'No available jobs under this section currently.': 'There are no available job listings in this category' }}
              </h4>
            </b-col>
          </b-overlay>
        </div>
        <!-- </div> -->
      </div>

      <!-- RegistrationForm Modal -->
      <div class="">
        <b-modal
          ref="popupReg"
          v-model="popupReg"
          centered
          hide-footer
          title="Join Event"
          size="lg"
        >
          <div class="w-100">
            <h6 class="mb-6">
              You will require a HeadHunters HQ account to proceed with the
              application.
            </h6>
            <b-tabs justified>
              <b-tab title="Login">
                <login-page
                  class="w-100"
                  which-page="listings"
                  :event-id="this.$router.currentRoute.path"
                  @user-logged-in="afterUserLoggedIn"
                />
              </b-tab>

              <b-tab title="Registration">
                <RegistrationForm
                  class="w-100"
                  :extra-data="popup_questions"
                  :event-id="event_id"
                  which-page="listings"
                  account-type="Applicant"
                  @user-registered="afterUserRegistered"
                />
              </b-tab>
            </b-tabs>
          </div>
        </b-modal>
      </div>

      <!-- Quiz Modal -->
      <JobPopup
        :activate-popup="popup_quiz"
        :job-questions="popup_questions"
        :save-nric="save_nric"
        :all-applications="registered"
        @application-form="checkForm"
        @application-id="setApplicationId"
      />
      <ProfileCompletionForm
        :activate-popup="popupProfileQuestions"
        @open-job-form="getQuestions"
        @open-completion-form="activateTypeForm"
      />
    </div>
    <help-desks-issue-floating />
    <div
      v-if="showLayout"
      class="container-fluid"
      :class="event_details && (Object.keys(event_details).length === 0 || event_details.active) ? '': 'footer'"
    >
      <AppFooter class="w-100" />
    </div>

  </div>
</template>

<script>

import HelpDesksIssueFloating from '@/components/HelpDesksIssueFloating.vue'

import SpinnerBtn from '@/layouts/components/spinners/SpinnerButton.vue'
import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBTooltip,
  VBModal,
  BBadge,
  BOverlay,
  BAlert,
  BTabs,
  BTab,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import VueSlider from 'vue-slider-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-slider-component/theme/default.css'
import {
  intersection, orderBy, toInteger, inRange, debounce,
} from 'lodash'
import AppNavbarFull from '@/layouts/components/AppNavbarFull.vue'
import AppFooter from '@/layouts/components/AppFooter.vue'
import {
  allIndustries,
  specialIndustries,
  jobFunctions,
} from '@/constants/datastore'
import JobPopup from '@/components/JobPopup.vue'
import LoginPage from '@/views/authentication/login/LoginForm.vue'
import RegistrationForm from '@/views/authentication/registration/RegistrationForm.vue'
import { onResizeMixins } from '@/components/mixins/onResizeMixins'
import { listingsMixin } from './mixins/listingsMixins'

export default {
  components: {
    SpinnerBtn,
    HelpDesksIssueFloating,

    // ListEvent,
    JobPopup,
    AppFooter,
    VueSlider,
    vSelect,
    BCard,
    BBadge,
    BAlert,
    BFormGroup,
    BModal,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BOverlay,

    BButton,
    BRow,
    BCol,
    BTabs,
    BTab,
    AppNavbarFull,

    LoginPage,
    RegistrationForm,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  mixins: [listingsMixin, onResizeMixins],
  props: {
    groupEventId: { type: String, default: '' },
  },

  data() {
    return {
      companySearchLoading: false,
      industrySearchLoading: false,
      preferred_position: [],
      isLoading: true,
      all_positions: [],
      all_share_icons: [
        {
          name: ['fab', 'facebook-square'],
          styling: 'facebook-color',
          network: 'facebook',
        },
        {
          name: ['fab', 'twitter-square'],
          styling: 'twitter-color',
          network: 'twitter',
        },
        {
          name: ['fab', 'whatsapp-square'],
          styling: 'whatsapp-color',
          network: 'whatsapp',
        },
        {
          name: ['fab', 'linkedin'],
          styling: 'linkedin-color',
          network: 'linkedin',
        },
        {
          name: ['fab', 'facebook-messenger'],
          styling: 'messenger-color',
          network: 'messenger',
        },
        {
          name: ['fab', 'telegram'],
          styling: 'telegram-color',
          network: 'telegram',
        },
      ],
      book: {
        text: 'Register',
        color: 'primary',
      },
      select_event_type: '',
      event_types: [
        { text: 'Single events', value: 'single' },
        { text: 'Group events', value: 'group' },
      ],
      group_preview_popup: false,
      group_preview_link: '',
      selected_job_type: [],
      sort_choice: 'Default',
      sort_options: [
        { text: 'Default', value: 'Default' },
        { text: 'Ascending', value: 'Ascending' },
        { text: 'Descending', value: 'Descending' },
      ],
      selected_industry: [],
      industries: [],

      selected_employment_type: [],
      job_types: [
        { text: 'Part-time', value: 'parttime' },
        { text: 'Full-time', value: 'fulltime' },
      ],
      employment_types: [
        { text: 'Internship/Traineeship', value: 'internship_traineeship' },
        { text: 'Freelance', value: 'freelance' },
        { text: 'Permanent', value: 'permanent' },
        { text: 'Contract', value: 'contract' },
        { text: 'Flexi-work', value: 'flexiwork' },
        { text: 'Temporary', value: 'temporary' },
      ],
      salary_formatter: v => `S$${`${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      selected_location: '',
      selected_flexi: '',
      all_flexi_filters: [
         {
          text: 'Flexi-Time',
          value: 'Flexi-Time',
        },
         {
          text: 'Flexi-Load',
          value: 'Flexi-Load',
        },
         {
          text: 'Flexi-Place',
          value: 'Flexi-Place',
        },
      ],
      selected_event_title: '',
      redirection_link: null,
      all_locations: [
        {
          text: 'North',
          value: 'North',
        },
        {
          text: 'South',
          value: 'South',
        },
        {
          text: 'Central',
          value: 'Central',
        },
        {
          text: 'East',
          value: 'East',
        },
        {
          text: 'West',
          value: 'West',
        },
      ],
      all_dates: [],
      specific_company: '',
      // $generateDownloadUrl(`company-profile-image/${specific_company_image}`)
      specific_company_image: '',
      event_id: '',
      fullname: '',
      email_address: '',
      show_inside: false,
      popupActive: false,
      image_url: 'default',
      event_exist: false,
      action: 'insert',
      current_item: {},
      popupReg: false,
      event_details: {},

      // Employments
      popupEmployments: false,
      // Educations
      popupEducations: false,

      item_id: '',
      job: {},
      companies: [],
      registered: [],
      events_data: [],
      all_events_data: [],
      select_tags: [],
      select_date: [],
      select_company: [],
      select_job_source: [],
      select_organiser: [],
      organiser_companies: [],
      current_user: '',
      position: '',
      description: '',
      skip_salary_filter: true,
      salary_range: [0, 500000],
      salary_range_monthly: [0, Math.round(500000 / 12)],
      salary_range_yearly: [0, 500000],
      min_salary_range: 0,
      max_salary_range: 500000,
      jobSources: [
        { text: 'HeadHunters HQ', value: 'HeadHunters HQ' },
        // { text: 'Talented', value: 'betalented' },
        { text: 'Others', value: 'Others' },
      ],
      categories: [
        { text: 'Administration, business and management', value: 1 },
        { text: 'Alternative therapies', value: 2 },
        { text: 'Animals, land and environment', value: 3 },
        { text: 'Computing and ICT', value: 4 },
        { text: 'Construction and building', value: 5 },
        { text: 'Design, arts and crafts', value: 6 },
        { text: 'Education and training', value: 7 },
        { text: 'Engineering', value: 8 },
        { text: 'Facilities and property services', value: 9 },
        { text: 'Financial services', value: 10 },
        { text: 'Garage services', value: 11 },
        { text: 'Hairdressing and beauty', value: 12 },
        { text: 'Healthcare', value: 13 },
        { text: 'Heritage, culture and libraries', value: 14 },
        { text: 'Hospitality, catering and tourism', value: 15 },
        { text: 'Legal and court services', value: 16 },
        { text: 'Manufacturing and production', value: 17 },
        { text: 'Performing arts and media', value: 18 },
        { text: 'Retail and customer services', value: 19 },
        { text: 'Science, mathematics and statistics', value: 20 },
        { text: 'Security, uniformed and protective services', value: 21 },
        { text: 'Social sciences and religion', value: 22 },
        { text: 'Sport and leisure', value: 23 },
        { text: 'Transport, distribution and logistics', value: 24 },
      ],
      dates: [],
      popup_event: {},
      current_event_id: '',
      share_popup: false,
      filter_popup: false,
      group_event_image: false,
      share_link: '',
      searchTimer: null,
      selected_status: true,
      all_event_status: [
        { text: 'Active', value: 'active' },
        { text: 'Inactive', value: 'inactive' },
      ],
      all_job_functions: [],
      selected_job_function: [],
      total_events: [],
      start_page_count: 0,
      end_page_count: 0,
      current_job_page: 1,
      page_size: 4,
      accountancy_event: false,
      pause_trigger: false,

      load_more_counter: 4,
      expand_icon: 'icon-minimize-2',
      expand_search: true,
      companyImage: '',
      appNavbarFullImageUrl: null,
      showLayout: true,

      base_listings: 'http://dashboard.headhuntershq.com/listings/',
      // base_listings: 'http://localhost:8082/listings/',
      viewMoreLoading: false,
      searchLoading: false,
      jobsLoading: false,
      mcf_message:
        'All information on jobs is published in good faith. e2i does not make any warranties about the completeness, reliability and accuracy of this information. From the jobs portal, you can visit other websites by following hyperlinks on the job ads to external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove any dead/unrelated/inappropriate content or links.',
      listingsPagination: {
        currentPage: 0,
        skipPages: 0,
        limit: 5,
      },
      activeFilters: {
        search: false,
        sort: false,
        company: false,
        location: false,
        employment_type: false,
        selected_industry: false,
        selected_job_function: false,
        select_date: false,
      },

      showEventButtons: true, // use to rerender buttons after submitting so it can turn to applied button

      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 5,
      },
      totalRecords: 0,
      isFiltered: false,

      firstLoad: true,
      filterTitleClass: 'my-1 font-weight-bolder',
      fetchedJobIds: {
        main: [],
        bot: [],
      },
    }
  },
  computed: {
    excludeRangeForEFC() {
      const efc_jobs = this.events_data.map(item => item.source_name === 'efc')
      if (!efc_jobs.includes(false)) {
        return true
      }

      return false
    },

    eventsGroups() {
      return Array.from(Array(Math.ceil(this.events_data.length / 4)).keys())
    },
    loadedAllJobs() {
      // if (this.load_more_counter < this.all_events_data.length) {
      if (this.$length(this.events_data) >= this.totalRecords) {
        return true
      }

      return false
    },
  },
  watch: {
    current_job_page: {
      handler(val, oldVal) {
        this.events_data = this.total_events[val - 1]
      },
    },

    selected_event_title: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.$set(this, 'events_data', [])
          this.$set(this, 'fetchedJobIds', {})
          this.specificGetEvents()
        }
        this.addParamsToUrl('query', val)
        // if (this.$isNotEmpty(val)) {
        //   this.addParamsToUrl('query', val)
        // } else {
        //   this.$removeParamsToUrl('query')
        // }
      },
    },
    salary_range_monthly: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          if (val[1] !== 0) {
            this.salary_range_yearly = [0, 0]
          }
          this.salary_range = [val[0] * 12, val[1] * 12]
        }
      },
    },
    salary_range_yearly: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          if (val[1] !== 0) {
            this.salary_range_monthly = [0, 0]
          }
          this.salary_range = [val[0], val[1]]
        }
      },
    },
    salary_range: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.$set(this, 'events_data', [])
          this.$set(this, 'fetchedJobIds', {})
          this.searchText()
        }
        this.addParamsToUrl('filtered_salary', val)
      },
    },
    selected_employment_type: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.$set(this, 'events_data', [])
          this.$set(this, 'fetchedJobIds', {})
          this.specificGetEvents()
        }
        this.addParamsToUrl('filtered_employment_types', val)
      },
    },
    selected_job_function: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.$set(this, 'events_data', [])
          this.$set(this, 'fetchedJobIds', {})
          this.specificGetEvents()
        }
        this.addParamsToUrl('filtered_job_functions', val)
      },
    },
    select_tags: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.$set(this, 'events_data', [])
          this.$set(this, 'fetchedJobIds', {})
          this.specificGetEvents()
        }
      },
    },
    select_date: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.$set(this, 'events_data', [])
          this.$set(this, 'fetchedJobIds', {})
          this.specificGetEvents()
        }
        this.addParamsToUrl('filtered_dates', val)
      },
    },
    select_company: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.$set(this, 'events_data', [])
          this.$set(this, 'fetchedJobIds', {})
          this.specificGetEvents()
        }
        this.addParamsToUrl('filtered_companies', val)
      },
    },
    select_job_source: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.$set(this, 'events_data', [])
          this.$set(this, 'fetchedJobIds', {})
          this.specificGetEvents()
        }
        this.addParamsToUrl('filtered_source', val)
      },
    },

    select_organiser: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.$set(this, 'events_data', [])
          this.$set(this, 'fetchedJobIds', {})
          this.specificGetEvents()
        }
        this.addParamsToUrl('filtered_organisers', val)
      },
    },
    selected_location: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.$set(this, 'events_data', [])
          this.$set(this, 'fetchedJobIds', {})
          this.specificGetEvents()
        }
        this.addParamsToUrl('filtered_locations', val)
      },
    },
    selected_flexi: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.$set(this, 'events_data', [])
          this.$set(this, 'fetchedJobIds', {})
          this.specificGetEvents()
        }
        this.addParamsToUrl('filtered_flexi_options', val)
      },
    },
    selected_job_type: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.$set(this, 'events_data', [])
          this.$set(this, 'fetchedJobIds', {})
          this.specificGetEvents()
        }
        this.addParamsToUrl('filtered_job_types', val)
      },
    },

    selected_industry: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.$set(this, 'events_data', [])
          this.$set(this, 'fetchedJobIds', {})
          this.specificGetEvents()
        }
        this.addParamsToUrl('filtered_industries', val)
      },
    },

    select_event_type: {
      handler(val, oldVal) {
        this.serverParams.page = 1
        this.$set(this, 'events_data', [])
        this.$set(this, 'fetchedJobIds', {})
        this.specificGetEvents()
      },
    },

    sort_choice: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
        this.addParamsToUrl('sort_position', val)
      },
    },
    popup_quiz: {
      handler(val, oldVal) {
      },
    },
    popupProfileQuestions: {
      handler(val, oldVal) {
      },
    },
    deep: true,
  },

  mounted() {
    try {
      const next = this.$children[0].$refs.btnclose
      next.$el.addEventListener('click', this.onClickCloseButton, false)
    } catch (error) {}
  },
  created() {
    // repopulate the filters from the URL
    if (!this.$isEmpty(this.$route.query)) {
      this.pause_trigger = true
      Object.entries(this.$route.query).forEach(([key, value]) => {
        switch (key) {
          case 'query':
            this.selected_event_title = value
            break
          case 'sort_position':
            this.sort_choice = value
            break
          case 'filtered_companies':
            this.select_company = value
            break
          case 'filtered_source':
            this.select_job_source = value
            break
          case 'filtered_locations':
            this.selected_location = value
            break
          case 'filtered_flexi_options':
            this.selected_flexi = value
            break
          case 'filtered_salary':
            this.salary_range = value
            break
          case 'filtered_job_types':
            this.selected_job_type = value
            break
          case 'filtered_employment_types':
            this.selected_employment_type = value
            break
          case 'filtered_industries':
            this.selected_industry = value
            break
          case 'filtered_job_functions':
            this.selected_job_function = value
            break

          default:
            break
        }
      })
    }

    if (this.$router.currentRoute.path.includes('/listings')) {
      this.showLayout = true
    } else {
      this.showLayout = false
    }
    if (window.screen.width < 769) {
      this.expand_search = false
    }
    this.end_page_count = this.page_size
    this.current_event_id = this.$route.params.company
    // this.getUser()
    // const { token } = this.$store.state.auth.ActiveUser
    this.email_address = this.$store.state.auth.ActiveUser
      ? this.$store.state.auth.ActiveUser.email_address
      : null
    // this.$http.defaults.headers.common["Authorization"] = `Token ${token}`;
    this.industries = allIndustries

    this.industries = orderBy(this.industries, ['text'], ['asc'])
    this.all_locations = orderBy(this.all_locations, ['text'], ['asc'])
    this.employment_types = orderBy(this.employment_types, ['text'], ['asc'])
    this.job_types = orderBy(this.job_types, ['text'], ['asc'])

    // typeform
    this.prefIndustryOptions = orderBy(allIndustries, ['value'], ['asc'])

    this.searchLoading = false
    this.jobsLoading = false
    if (this.current_event_id) {
      if (this.current_event_id.includes('_g')) {
        const eventID = this.current_event_id.replace('_g', '')
        localStorage.setItem('track_event_id', eventID)
      }
    } else {
      localStorage.removeItem('track_event_id')
      localStorage.removeItem('track_job_id')
    }

    if (this.groupEventId.length > 2) {
      this.getEvents(this.groupEventId, 'group_event')
    }
    this.specificGetEvents()
  },
  methods: {
    searchForCompany: debounce((loading, search, vm) => {
      vm.getCompanyFilterOptions(search)
    }, 1500),
    companySearch(search = '', loading = null) {
      this.searchForCompany(loading, search, this)
    },
    searchForIndustry: debounce((loading, search, vm) => {
      vm.getIndustryFilterOptions(search)
    }, 1500),
    industrySearch(search = '', loading = null) {
      this.searchForIndustry(loading, search, this)
    },
    goBack() {
      if (this.prevRoute && this.$isNotEmpty(this.prevRoute.name)) {
        this.$router.go(-1)
      } else if (this.$route.params.dashboard === 'dashboard' || this.$route.name === 'view-jobs-company') {
        // this.$router.push({ name: 'view-jobs' })
        window.location.href = '/events-list'
      } else {
        // this.$router.push({ name: 'listings-page' })
        window.location.href = '/listings'
      }
    },
    updateApplications(val) {
      this.registered = val
      this.$forceUpdate()
    },
    checkForm(val) {
      this.popup_quiz = val
    },
    activateTypeForm(val) {
      this.popupProfileQuestions = val
    },
    getQuestions(val) {
      this.popup_quiz = true
      this.popup_questions = val.questions
      // this.save_nric = val.save_nric
      this.save_nric = false
    },
    checkProfileCompletion() {
      let incomplete = true
      try {
        incomplete = this.$store.state.auth.ActiveUser.profile_status.incomplete
      } catch (error) {
        incomplete = true
      }
      return incomplete
    },
    loadMoreJobs() {
      this.listingsPagination.currentPage += 5
      this.listingsPagination.skipPages += 5

      this.serverParams.page += 1
      this.viewMoreLoading = true
      this.specificGetEvents()
    },
    cleanLocation(location, zone) {
      let { address } = location
      const { country } = location
      address = !this.$isEmpty(address) ? address.replace(`${country} `, '') : address

      let currentZone = zone
      if (location.postal_code) {
        if (location.postal_code === '000000' || location.postal_code.includes('999999')) {
          currentZone = 'Islandwide'
        } else if (currentZone === undefined || currentZone === null) {
          currentZone = 'NA'
        }
      }
      let data = `${currentZone} (${location.postal_code})`.replace('999999', '').replace(' null', '').replace('null', '')
      data = data.replace('(null)', 'NA').replace('()', '')
      return data
      // return `${currentZone} (${location.country} ${location.postal_code})`.replace(' null', '').replace('null', '')
    },
    partialReg() {
      this.$bvModal
        .msgBoxConfirm(
          "Currently, your application isn't viewable by the employer since you haven't completed your profile."
            + ' \n\nClicking the button below will redirect you to your profile page where you can fill up the required details.',
          {
            title: 'Incomplete Profile',
            size: 'lg',
            okVariant: 'warning',
            okTitle: 'My Profile',
            cancelTitle: 'Go Back',
            cancelVariant: 'primary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            window.location.href = '/profile'
          }
        })
    },

    resetFilters() {
      this.activeFilters = {
        search: false,
        sort: false,
        select_job_source: false,
        company: false,
        location: false,
        employment_type: false,
        selected_industry: false,
        selected_job_function: false,
        select_date: false,
      }
      this.selected_event_title = ''
      this.pause_trigger = true
      this.sort_choice = 'Default'
      this.select_company = []
      this.select_job_source = []
      this.select_organiser = []
      this.selected_location = ''
      this.skip_salary_filter = true
      this.salary_range = [0, 500000]
      this.selected_job_type = []
      this.selected_employment_type = []
      this.selected_industry = []
      this.selected_job_function = []
      this.select_job_source = []
      this.select_date = []
      this.specificGetEvents()
      //
    },
    grouped() {
      const { company } = this.$route.params
      return company
    },
    fireRedirect(item) {
      window.open(item.redirection_url, '_blank')
    },

    checkRedirection() {
      if (this.redirection_link) {
        window.open(this.redirection_link, '_blank')
      }
    },

    specificGetEvents() {
      if (!this.viewMoreLoading) {
        this.jobsLoading = true
      }
      this.searchLoading = true
      this.load_more_counter = 4
      if (this.$route.params.company !== undefined) {
        this.specific_company = this.$route.params.company

        if (this.$route.params.company.endsWith('_g')) {
          const eventId = this.$route.params.company.replace('_g', '')
          if (eventId === '61de74bc21c14eb016e518cb' || eventId === 'e88e4812-82c8-4d49-8aa1-0b3e3129bb43') {
            this.industries = specialIndustries
          } else if (eventId === '61dcd0dc62adac05c99714c4' || eventId === 'ed14fa6a-0dc1-4fa2-a6ca-53d5daad5d2c') {
            this.accountancy_event = true
            this.$emit('accountancy-event', this.accountancy_event)
          }
          this.getEvents(eventId, 'group_event')
        } else {
          const eventId = this.$route.params.company
          if (this.$route.params.company === '61de74bc21c14eb016e518cb' || eventId === 'e88e4812-82c8-4d49-8aa1-0b3e3129bb43') {
            this.industries = specialIndustries
          } else if (eventId === '61dcd0dc62adac05c99714c4' || eventId === 'ed14fa6a-0dc1-4fa2-a6ca-53d5daad5d2c') {
            this.accountancy_event = true
            this.$emit('accountancy-event', this.accountancy_event)
          }
          this.getEvents(this.$route.params.company_id, 'employer')
        }
      } else {
        this.getEvents()
      }
    },
    cleanCycles(dirtyCycle) {
      const equivCycles = {
        yearly: 'per Year',
        monthly: 'per Month',
        weekly: 'per Week',
        daily: 'per Day',
        hourly: 'per Hour',
      }

      const cleanedCycle = !this.$isEmpty(dirtyCycle) ? equivCycles[dirtyCycle.toLowerCase()] : ''

      return cleanedCycle
    },
    searchText() {
      clearTimeout(this.searchTimer)

      this.searchTimer = setTimeout(() => {
        this.specificGetEvents()
      }, 1000)
    },
    shareEvent(item) {
      this.current_item = item
      this.share_link = this.getLink(item.id)
      this.$refs['share-job-modal'].toggle('#toggle-btn')
      this.share_popup = true
    },
    filterEvent() {
      this.$refs['filter-job-modal'].toggle('#toggle-btn')
    },
    filterPosition() {
      this.$refs['filter-position-modal'].toggle('#toggle-btn')
    },
    filterEmployer() {
      this.$refs['filter-employer-modal'].toggle('#toggle-btn')
    },
    filterWork() {
      this.$refs['filter-work-modal'].toggle('#toggle-btn')
    },
    filterSalary() {
      this.$refs['filter-salary-modal'].toggle('#toggle-btn')
    },
    filterEmployment() {
      this.$refs['filter-employment-modal'].toggle('#toggle-btn')
    },
    filterSort() {
      this.$refs['filter-sort-modal'].toggle('#toggle-btn')
    },
    getEventType(value) {
      if (value === 'virtual') {
        return 'Virtual Interview'
      }
      if (value === 'physical') {
        return 'In-Person Interview'
      }
      if (value === 'speed_interview') {
        return 'Virtual Speed Interview'
      }
      return 'NA'
    },
    goToEvents(eventId) {
      this.event_id = eventId

      localStorage.setItem('current_event', eventId)
      // let route = this.$router.resolve({ path: "/events/" + event_id });
      //   this.group_preview_link = route.href
      this.group_preview_popup = true
      // let route = this.$router.resolve({ path: "/events/" + event_id });

      // window.open(route.href, "_blank");
    },
    getLink(eventId) {
      return `${window.location.origin}/listing/${eventId}`
    },
    onCopy(eventId) {
      this.copyTextToClipboard(`${window.location.origin}/listing/${eventId}`)
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Job Link Alert',
            icon: 'AlertCircleIcon',
            text: 'Job link copied successfully',
            variant: 'success',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        () => {},
        err => {},
      )
    },

    bookEvent() {
      if (this.book.text === 'Applied') {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Job Application Update',
              icon: 'AlertCircleIcon',
              text: 'You have already applied for this job.',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      } else if (this.email_address.length > 4 && this.fullname.length > 3) {
        const all_data = {
          event_id: this.event_id,
          email_address: this.email_address,
          fullname: this.fullname,
          approved: false,
          interviewed: false,
          company: this.$store.state.auth.ActiveUser.company_name,
        }
        this.$http
          .post('/api/book', { data: all_data })
          .then(response => {
            const { success } = response.data
            if (success) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Registration Update',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )
              // this.book.text = "Registered";
              // this.book.color = "success";
              this.popupReg = false
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Registration Update',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
          })
          .catch(error => {})
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Invalid Data',
              icon: 'AlertCircleIcon',
              text: 'Please enter a valid email address or full name',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    viewMore(item) {
      localStorage.setItem('current_event', item.id)
      this.show_inside = true
      this.job = item
      this.popupActive = true
    },
    trimMyString(string) {
      const start = 0
      const maxLength = 200
      // Note - `start` is if I want to start after some point of the string
      if (string.length > maxLength) {
        const trimmedString = string.substr(start, maxLength)
        return `${trimmedString.substr(
          start,
          Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')),
        )} ......`
      }
      return string
    },
    filter_events(data) {
      // this.$vs.loading({ container: "#div-with-loading", scale: 0.6 });

      let new_events = false
      const min_salary = toInteger(data.min_salary)
      const max_salary = toInteger(data.salary)
      const salary_cycle = data.salary_cycle.toLowerCase()
      let multiplier = 1

      if (salary_cycle === 'yearly') {
        multiplier = 1
      } else if (salary_cycle === 'weekly') {
        multiplier = 52
      }
      const salary = inRange(
        min_salary * multiplier,
        this.salary_range[0],
        this.salary_range[1],
      )
        || inRange(
          max_salary * multiplier,
          this.salary_range[0],
          this.salary_range[1],
        )

      const comp = this.select_company.length > 0
        ? this.select_company.includes(data.company)
        : true

      const source = this.select_job_source.length > 0
        ? this.select_job_source.includes(data.source)
        : true

      const organiser = this.select_organiser.length > 0
        ? this.select_organiser.includes(data.organiser)
        : true
      const location = this.selected_location.length > 0
        ? this.selected_location.includes(data.location_zone)
        : true

      const flexi_options = this.selected_flexi.length > 0
            ? this.selected_flexi.includes(data.flexi_options)
            : true
      const similar_industry = intersection(this.selected_industry, data.tags)

      const tags = this.selected_industry.length > 0 ? similar_industry.length > 0 : true

      const event_type = this.select_event_type.length > 0
        ? this.select_event_type.includes(data.event_category)
        : true

      const similar_job_functions = intersection(
        this.selected_job_function,
        data.job_functions,
      )
      const job_function = this.selected_job_function.length > 0
        ? similar_job_functions.length > 0
        : true

      const employment_type = this.selected_employment_type.length > 0
        ? this.selected_employment_type.some(v => data.employment_type.includes(v))
        : true

      const job_type = this.selected_job_type.length > 0
        ? this.selected_job_type.includes(data.job_type)
        : true

      const date = this.select_date.length > 0
        ? this.select_date.includes(data.start_date)
        : true

      const title = this.selected_event_title.length > 0
        ? data.position
          .toLowerCase()
          .includes(this.selected_event_title.toLowerCase())
        : true

      if (
        comp
        && source
        && organiser
        && tags
        && location
        && flexi_options
        && event_type
        && job_type
        && date
        && title
        && job_function
        && employment_type
        && salary
      ) {
        new_events = true
      }

      return new_events
      // this.events_data = new_events;
      // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
    },

    smartFilter(data) {
      const { available_job_functions } = data
      const { available_job_types } = data
      const { available_industries } = data
      const { available_employment_types } = data

      if (available_job_functions.length > 0) {
        this.all_job_functions = available_job_functions.map(industry => ({ text: industry, value: industry }))
      } else {
        this.all_job_functions = []
      }

      if (available_job_types.length > 0) {
        this.job_types = this.job_types.filter(jobType => (jobType.value.length > 0
          ? available_job_types.includes(jobType.value)
          : true))
      } else {
        // this.job_types = []
      }

      if (available_industries.length > 0) {
        this.industries = available_industries.map(industry => ({ text: industry, value: industry }))
      } else {
        this.industries = []
      }

      if (available_employment_types.length > 0) {
        this.employment_types = this.employment_types.filter(empTypes => available_employment_types.includes(empTypes.value))
      } else {
        this.employment_types = []
      }
    },
    firstSelection(field) {
      if (!this.activeFilters[field]) {
        this.events_data = []
        this.all_events_data = []
        this.registered = {}
        this.listingsPagination.skipPages = 0
      }

      this.activeFilters[field] = true
    },
    async getEvents(companyFilter, special = '') {
      const { email_address } = this.$store.state.auth.ActiveUser
        ? this.$store.state.auth.ActiveUser
        : { email_address: null }

      this.current_user = email_address

      const companyName = this.$route.params.company_name

      const all_event_group = []

      function attachSymbol(text) {
        return text.length > 0 ? '&' : '?'
      }

      this.serverParams.columnFilters = {}

      if (special === 'group_event') {
        this.serverParams.columnFilters.group_event = companyFilter
      }

      if (this.sort_choice !== 'Default') {
        this.serverParams.columnFilters.sort_position = this.sort_choice
        this.firstSelection('sort')
        // this.serverParams.columnFilters.sort_position = this.sort_choice
      }

      if (companyName) {
        this.serverParams.columnFilters.company_name = companyName
      }
      if (this.$length(this.selected_event_title) > 0) {
        this.serverParams.columnFilters.query = this.selected_event_title
        this.firstSelection('search')
      }

      if (this.$length(this.select_company) > 0) {
        this.serverParams.columnFilters.filtered_companies = this.select_company
        this.firstSelection('employer')
      }

      if (this.$length(this.select_job_source) > 0) {
        this.serverParams.columnFilters.filtered_source = this.select_job_source
        this.firstSelection('source')
      }

      if (this.$length(this.select_organiser) > 0) {
        this.serverParams.columnFilters.filtered_organisers = this.select_organiser
      }

      if (this.$length(this.selected_location) > 0) {
        this.serverParams.columnFilters.filtered_locations = this.selected_location
        this.firstSelection('location')
      }

      if (this.$length(this.selected_flexi) > 0) {
        this.serverParams.columnFilters.filtered_flexi_options = this.selected_flexi
        this.firstSelection('flexi_options')
      }

      if (this.$length(this.selected_employment_type) > 0) {
        this.serverParams.columnFilters.filtered_employment_types = this.selected_employment_type
        this.firstSelection('employment_type')
      }

      if (this.$length(this.selected_industry) > 0) {
        this.serverParams.columnFilters.filtered_industries = this.selected_industry
        this.firstSelection('selected_industry')
      }

      if (this.$length(this.selected_job_function) > 0) {
        this.serverParams.columnFilters.filtered_job_functions = this.selected_job_function
        this.firstSelection('selected_job_function')
      }

      if (this.$length(this.select_date) > 0) {
        this.serverParams.columnFilters.filtered_dates = this.select_date
        this.firstSelection('select_date')
      }

      if (this.$length(this.salary_range) > 0) {
        // in order to load jobs with no min / max salary specified
        if (
          (parseInt(this.min_salary_range, 0) === 0 && parseInt(this.max_salary_range, 0) === 500000) // this is for the firs init load
            || (this.salary_range[0] === this.min_salary_range && this.salary_range[1] === this.max_salary_range) // if user did not change the min / max salary range
        ) {
          this.skip_salary_filter = true
          this.serverParams.columnFilters.filtered_salary = [0, 999999999]
        } else {
          this.skip_salary_filter = false
          this.serverParams.columnFilters.filtered_salary = this.salary_range
        }
      }
      if (this.select_date.length > 0) {
        this.serverParams.columnFilters.filtered_dates = this.select_date
      }

      if (this.selected_job_type.length > 0) {
        this.serverParams.columnFilters.filtered_job_types = this.selected_job_type
        this.firstSelection('selected_industry')
      }

      let query = ''
      if (this.serverParams.page === 1) {
        this.events_data = []
        this.all_events_data = []
        this.registered = {}
      }

      this.isFiltered = this.checkIsFiltered()
      if (this.isFiltered) {
        this.serverParams.featured_job_ids = []
      }

      this.getListingsFilterOptions(companyFilter, special)

      query = `?params=${encodeURIComponent(JSON.stringify({ ...this.serverParams, isFiltered: this.isFiltered }))}`

      // query = `?params=${JSON.stringify({ ...this.serverParams })}`
      this.isLoading = true
      const response = await this.$http
        .post(`/api/all-events${query}`, {
          filter_data: {
            fetched_job_ids: this.fetchedJobIds,
          },
        })
      if (response.data.success) {
        this.isLoading = false
        this.event_details = response.data.event_details
        this.fetchedJobIds = response.data.fetched_job_ids

        this.event_exist = true
        this.registered = {
          ...this.registered,
          ...response.data.registered,
        }

        // this.companies = response.data.companies
        // this.organiser_companies = response.data.organisers

        // this.companies = orderBy(this.companies, ['text'], ['asc'])
        // this.organiser_companies = orderBy(this.organiser_companies, ['text'], ['asc'])

        // this.min_salary_range = Math.round(response.data.min_max_salary[0] * 12)
        // this.max_salary_range = Math.round(response.data.min_max_salary[1] * 12)
        // this.all_locations = response.data.locations
        // const jobFunctions = response.data.available_job_functions.map(item => ({ text: item, value: item }))

        // this.all_job_functions = orderBy(jobFunctions, ['text'], ['asc'])
        // this.all_dates = response.data.all_dates
        // this.all_dates = orderBy(this.all_dates, ['text'], ['asc'])
        // this.smartFilter(response.data)

        // eslint-disable-next-line no-restricted-syntax
        for (const item of response.data.message) {
          const output = item
          this.events_data.push(item)
          // if (this.$route.params.company) {
          //   this.companyImage = item.company_image
          //   this.$emit('image-update', item.organiser_image)
          //   this.group_event_image = await this.$generateDownloadUrl(item.event_banner)
          // }
        }
        this.totalRecords = response.data.total_records
        this.serverParams.featured_job_ids = response.data.featured_job_ids
        this.getEventFilesUrl()
        this.updateMetaData()
      } else {
        this.isLoading = false
        this.event_exist = false
        // this.searchLoading = false
      }
      this.pause_trigger = false
      this.jobsLoading = false
      this.viewMoreLoading = false

      // this.searchLoading = false
      // .then(response => {

      // })
      // .catch(error => {
      //   this.$handleErrorResponse(error)
      //   this.pause_trigger = false
      //   this.jobsLoading = false
      //   this.searchLoading = false
      // })

      // this.$http
      //   .get(`/api/top-events`)
      //   .then(response => {
      //     console.log(response)
      //   })
      //   .catch(error => {
      //     this.$handleErrorResponse(error)
      //   })
      // this.$http
      //   .get(`/api/top-jobs`)
      //   .then(response => {
      //     console.log(response)
      //   })
      //   .catch(error => {
      //     this.$handleErrorResponse(error)
      //   })
    },
    async getCompanyFilterOptions(companyName) {
      this.companySearchLoading = true
      let query = ''
      this.serverParams.companyNameFilter = true
      this.serverParams.companyNameSearch = companyName
      query = `?params=${encodeURIComponent(JSON.stringify(this.serverParams))}`

      const response = await this.$http
        .get(`/api/all-events-filter-options${query}`)
      if (response.data.success) {
        this.companies = response.data.companies
        this.companies = orderBy(this.companies, ['text'], ['asc'])
      }
      // this.pause_trigger = false
      // this.jobsLoading = false
      this.companySearchLoading = false
    },
    async getIndustryFilterOptions(industryName) {
      this.industrySearchLoading = true
      let query = ''
      this.serverParams.industryNameFilter = true
      this.serverParams.industryNameSearch = industryName
      query = `?params=${encodeURIComponent(JSON.stringify(this.serverParams))}`

      const response = await this.$http
        .get(`/api/all-events-filter-options${query}`)
      if (response.data.success) {
        const { available_industries } = response.data
        if (available_industries.length > 0) {
          this.industries = available_industries.map(industry => ({ text: industry, value: industry }))
        } else {
          this.industries = []
        }
        this.industries = orderBy(this.industries, ['text'], ['asc'])
      }
      // this.pause_trigger = false
      // this.jobsLoading = false
      this.industrySearchLoading = false
    },

    async getListingsFilterOptions(companyFilter, special = '') {
      let query = ''
      if (this.serverParams.page === 1) {
        this.events_data = []
        this.all_events_data = []
        this.registered = {}
      }
      const companyNameFilterExists = this.serverParams.companyNameFilter
      if (companyNameFilterExists) {
        delete this.serverParams.companyNameFilter
      }

      query = `?params=${encodeURIComponent(JSON.stringify(this.serverParams))}`

      const response = await this.$http
        .get(`/api/all-events-filter-options${query}`)

      if (response.data.success) {
        this.event_exist = true

        // this.companies = response.data.companies
        // this.companies = orderBy(this.companies, ['text'], ['asc'])
        this.organiser_companies = response.data.organisers

        this.organiser_companies = orderBy(this.organiser_companies, ['text'], ['asc'])
        this.min_salary_range = response.data.min_max_salary[0]
        this.max_salary_range = response.data.min_max_salary[1]
        if (this.firstLoad) {
          setTimeout(() => {
            this.pause_trigger = true
            this.salary_range = [this.min_salary_range, this.max_salary_range]
          }, 100)
          setTimeout(() => {
            this.pause_trigger = false
            this.firstLoad = false
          }, 200)
        }

        this.all_locations = response.data.locations

        // this.all_flexi_filters = response.data.all_flexi_filters

        const jobFunctions = response.data.available_job_functions.map(item => ({ text: item, value: item }))
        this.all_job_functions = orderBy(jobFunctions, ['text'], ['asc'])
        this.all_dates = response.data.all_dates
        this.all_dates = orderBy(this.all_dates, ['text'], ['asc'])
        this.smartFilter(response.data)
      } else {
        this.event_exist = false
        this.searchLoading = false
      }
      this.pause_trigger = false
      // this.jobsLoading = false
      this.searchLoading = false
    },
    updateFavicon(iconLink) {
      // Create a new link element for the favicon
      const newFavicon = document.createElement('link')
      newFavicon.rel = 'icon'
      newFavicon.href = iconLink
      newFavicon.type = 'image/x-icon'

      // Find the existing favicon link element and remove it
      const existingFavicon = document.querySelector('link[rel="icon"]')
      if (existingFavicon) {
        document.head.removeChild(existingFavicon)
      }

      // Add the new favicon link element to the document head
      document.head.appendChild(newFavicon)
    },
    async updateMetaData() {
      if (this.$route.params.company) {
        // console.log(this.events_data)
        // if (this.$isNotEmpty(event_data.organiser_image)) {
        //   const organiser_image_url = await this.$generateDownloadUrl(event_data.organiser_image)
        //   this.updateFavicon(organiser_image_url)
        // }

        document.title = this.event_details.event_name
      }
    },
    async getEventFilesUrl() {
      if (this.$route.params.company) {
        this.companyImage = this.event_details.company_image
        this.appNavbarFullImageUrl = this.event_details.organiser_image
        // this.refs['refAppNavbarFull'].$emit('image-update', organiser_image_url)
        this.group_event_image = this.event_details.event_banner
      }
      this.events_data.forEach(async event => {
        if (this.$isEmpty(event.company_loa_file_url)) {
          this.events_data.company_loa_file_url = await this.$generateDownloadUrl(event.company_loa_file)
        }
        if (this.$isEmpty(event.organiser_loa_file_url)) {
          this.events_data.organiser_loa_file_url = await this.$generateDownloadUrl(event.organiser_loa_file)
        }
      })
    },

    checkIsFiltered() {
      this.isFiltered = false

      const filters = this.serverParams.columnFilters
      if (this.$isNotEmpty(filters.filtered_companies)) {
        this.isFiltered = true
      } else if (this.$isNotEmpty(filters.filtered_locations)) {
        this.isFiltered = true
      } else if (this.$isNotEmpty(filters.filtered_source)) {
        this.isFiltered = true
      } else if (this.$isNotEmpty(filters.filtered_job_types)) {
        this.isFiltered = true
      } else if (this.$isNotEmpty(filters.filtered_employment_types)) {
        this.isFiltered = true
      } else if (this.$isNotEmpty(filters.filtered_industries)) {
        this.isFiltered = true
      } else if (this.$isNotEmpty(filters.filtered_job_functions)) {
        this.isFiltered = true
      } else if (this.$isNotEmpty(filters.filtered_salary)) {
        if (
          (Number(this.min_salary_range) === 0 && Number(this.max_salary_range) === 500000)
            || (Number(this.salary_range[0]) === this.min_salary_range && Number(this.salary_range[1]) === this.max_salary_range)
            || (Number(filters.filtered_salary[0]) === 0 && Number(filters.filtered_salary[1]) === 999999999)
            || (Number(filters.filtered_salary[0]) === 0 && Number(filters.filtered_salary[1]) === 500000)
        ) {
          this.isFiltered = false
        } else {
          this.isFiltered = true
        }
      }

      return this.isFiltered
    },

    extract_values() {
      const selected_tags = []
      for (const data of this.categories) {
        for (const categ of this.select_tags) {
          if (data.value === categ) {
            selected_tags.push(data.text)
          }
        }
      }
      let selected_date = ''
      for (const data of this.dates) {
        if (data.value === this.select_date) {
          selected_date = data.text
        }
      }

      const all_data = {
        position: this.position,
        description: this.description,
        date: selected_date,
        tags: selected_tags,
        image_url: this.image_url,
      }
      return all_data
    },

    onClickCloseButton() {
      this.$emit('closePopup')
    },

    cleanerIntervals(start_date, end_date) {
      if (start_date === end_date) {
        return `happening on ${start_date}`
      }
      return `happening from ${start_date} to ${end_date}`
    },

    checkIDRegistered(registered, job_id) {
      const id = this.$store.state.auth.ActiveUser
        ? this.$store.state.auth.ActiveUser.id
        : null
      if (registered) {
        if (Object.keys(registered).includes(job_id)) {
          return registered[job_id].includes(id)
        }
        return false
      }
      return false
    },
    addParamsToUrl(keyName, value) {
      if (this.$isNotEmpty(value)) {
        this.$router.push({ path: this.$route.fullPath, query: { [keyName]: value } })
      // } else {
      //   this.$removeParamsToUrl(keyName)
      }
    },
    $removeParamsToUrl(keyName) {
      switch (keyName) {
        case 'query':
          this.selected_event_title = ''
          this.$replaceUrl(this, this.$route.name, this.$route.params, { query: null })
          // this.$set(this.activeFilters, 'search', false)
          break

        default:
          break
      }

      const newQuery = this.$route.query
      delete newQuery[keyName]
      this.$router.push({ path: this.$route.fullPath, query: newQuery })
      // this.specificGetEvents()
    },
    $removeAllParamsToUrl() {
      this.$router.push({ path: this.$route.fullPath })
    },
    jobPositionSearch(search, loading) {
      if (search.length > 0) {
        loading(true)
        this.searchForJob(loading, search, this)
      }
    },
    searchForJob: debounce((loading, search, vm) => {
      vm.positionSearch(search, loading)
    }, 1000),

    positionSearch(query, loading = null) {
      this.all_positions = []

      const eventId = this.$route.params.company ? this.$route.params.company.replace('_g', '') : 'nil'
      this.$http
        .get(`/api/position-search/${query}/${eventId}`)
        .then(response => {
          const record = response.data

          if (record.success) {
            this.all_positions = record.data
            // record.data.map(data => {
            //   this.all_positions.push(data)
            // })
          }
          if (this.$isNotEmpty(query)) {
            if (record.data.length > 0) {
              this.all_positions.unshift(query)
            }
          }

          if (loading) {
            loading(false)
          }
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },
  },
}
</script>

<style lang="scss">
.job-card {
  .mt-1 {
    margin-top: .5rem !important;
  }
}

.blurred-image {
  filter: blur(6px);
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  color: white;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #EA5455;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px; /* Height of the footer */
  text-align: left;
}

.v-select input {
 // cursor: pointer;
}
// div {
//   white-space: pre-wrap;
// }

.sticky-element {
  position: -webkit-sticky !important; /* Safari */
  position: static !important;
  top: 0;
}
.vue-slider-process {
  background-color: #ff9f43 !important;
}

.vue-slider-dot-tooltip-text {
  border-color: #ff9f43 !important;
  background-color: #ff9f43 !important;
}
.vue-slider-dot-tooltip-inner {
  border-color: #ff9f43 !important;
  background-color: #ff9f43 !important;
}
.image-size {
  @media (max-width: 800px) {
    max-width: 100%;
    max-height: 100%;
    object-fit: scale-down;
  }
  @media (min-width: 801px) {
    max-width: 70%;
    max-height: 70%;
    object-fit: scale-down;
  }
}

hr.solid {
  border-top: 3px solid #bbb;
}

.divider {
  @media (min-width: 800px) {
    width: 1px;
    background-color: rgba(94, 91, 91, 0.11);
    margin-left: 0px;
    margin-right: 0px;
    height: 5cm;
  }
}
.icon-header {
  margin-left: 5px;
  margin-top: -1px;
}

.typeform-question-container {
  min-height: 30vh;
  max-height: 80vh;
}

hr {
  margin-top: 1rem;
  margin-bottom: 0rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

@import "@core/scss/vue/libs/vue-select.scss";

.filter-button-container {
  // flex: 1;
  // display: flex;
  margin-block: 1em;
  width: 100%;
  overflow-x: scroll;
  // -webkit-overflow-scrolling: touch;

  .filter-button-box {
    // display: flex;
    // min-width: 70em;
    margin-bottom: 6px;
    display: flex;
    // flex-wrap: wrap;
    // flex-direction: column;

    -webkit-overflow-scrolling: touch;

    .btn-icon {
      white-space: nowrap;
      margin: 0 0.5em;
    }
    .btn-icon:first-of-type {
      margin: 0 0.5em 0 0;
    }
    .btn-icon:last-of-type {
      margin: 0 0 0 0.5em;
    }
  }
}
</style>
